import { ACPVariant } from 'apps/acp/variants/acp-variant';

const colors = {
  neutral: {
    color50: '#fcfcfc',
    color100: '#f0f3f3',
    color200: '#f3f3f4',
    color300: '#e6e7e8',
    color400: '#c4c5c1',
    color500: '#a3a49a',
    color600: '#818272',
    color700: '#5f604b',
    color800: '#3f4032',
    color900: '#202019'
  },
  default: {
    color50: '#d5e4f1',
    color100: '#aac9e3',
    color200: '#80aed5',
    color300: '#5593c6',
    color400: '#2b78b8',
    color500: '#005daa',
    color600: '#004a88',
    color700: '#003866',
    color800: '#002544',
    color900: '#001322'
  },
  accent: {
    color50: '#eef5fb',
    color100: '#dceaf7',
    color200: '#cbe0f3',
    color300: '#b9d5ee',
    color400: '#a8cbea',
    color500: '#96c0e6',
    color600: '#789ab8',
    color700: '#5a738a',
    color800: '#3c4d5c',
    color900: '#1e262e'
  },
  positive: {
    color50: '#e7f2df',
    color100: '#cee6bf',
    color200: '#b6d99f',
    color300: '#9dcc7f',
    color400: '#85c05f',
    color500: '#6cb33f',
    color600: '#568f32',
    color700: '#416b26',
    color800: '#568f32',
    color900: '#6cb33f'
  },
  negative: {
    color50: '#fdeadb',
    color100: '#fcd5b6',
    color200: '#fac092',
    color300: '#f8aa6e',
    color400: '#f79549',
    color500: '#f58025',
    color600: '#c4661e',
    color700: '#934d16',
    color800: '#62330f',
    color900: '#311a07'
  },
  special: {
    color50: '#fdeadb',
    color100: '#fcd5b6',
    color200: '#fac092',
    color300: '#f8aa6e',
    color400: '#f79549',
    color500: '#f58025',
    color600: '#c4661e',
    color700: '#934d16',
    color800: '#62330f',
    color900: '#311a07'
  }
};

const variant: ACPVariant = {
  id: 'www.brinksmoneyallaccess.com',
  productTitle: "Brink's Money All-Access Account by BofI Federal Bank",
  productType: 'consumer_dda',
  accessDomain: {
    subdomain: 'www',
    domain: 'brinksmoneyallaccess',
    topLevelDomain: 'com'
  },
  colors,
  legacyAcpTheme: 'brinks-all-access',
  legacyAcpBrandName: 'brinks-all-access',
  legacyLegosTheme: 'brinks',
  status_bar_background_color: colors.default.color500,
  status_bar_foreground_color: 'light',
  contactInfo: {
    name: "Brink's Money All-Access",
    contact_address_line1: 'PO Box 2136',
    contact_address_line2: 'Austin, TX 78768-2136',
    contact_address_line3: '',
    customer_support_email: 'customerservice@brinksmoneyallaccess.com',
    fax_formatted: '(866)-358-0526',
    phone_formatted: '(877)-849-3240',
    phone: '8778493240',
    phone_international: '737-220-8956',
    balance_check_phone: '8663877363',
    balance_check_phone_formatted: '1-866-387-7363',
    pre_funded_checks_phone: '18778147683',
    pre_funded_checks_phone_formatted: '1-877-814-7683'
  },
  configuration: {
    apiKeys: {
      appsFlyerDevKey: '5Us7NeWk6WVjGLsAnKioSk',
      'google-tag-manager-container-id': 'GTM-NQXKPD',
      'google-tag-manager-container-id-mobile': 'GTM-MWBTT5M',
      paypalFraudNetPayerIdProd: '',
      paypalFraudNetPayerIdTest: ''
    },
    featureToggles: {
      activationSetExternalId: false,
      appsFlyerEnabled: false,
      combinedTransactionsMasterAndSub: false,
      'embedded-activation': true,
      embeddedPayNearMe: false,
      hasNotificationCenter: false,
      mobilePBREnabled: true,
      preOnboardingEnabled: false,
      showAccountDisplayNickname: false,
      showAcquisitionLink: false,
      showATMFriendlyCards: true,
      showDisplayNameForSubAccount: false,
      showEmailInformationButton: true,
      showFeePlanPage: false,
      showHowToEarnRewardPointLink: true,
      showRegisterLink: false,
      showScanCardRegistration: false,
      showMarketingSiteLink: true,
      showFreeAtmFinderLink: false,
      showWUTransfers: true,
      showReloadLocationLink: false
    },
    misc: {
      distributor: 'brinks_gpr',
      layoutComponentOverride: false,
      loader: 'generic',
      payNearMeButtonTextColor: '#FFFFFF',
      payNearMeActionBarBackgroundColor: '#0092BC',
      payNearMeTitleTextColor: '#FFFFFF',
      payNearMeColorAccent: '#84bd00',
      marketingSiteUrl: 'https://www.brinksmoneyallaccess.com',
      marketingSiteLinkText: 'brinksmoneyallaccess.com',
      locationSearchMax: '25'
    },
    content: {
      copyrightStatement:
        'Netspend Corporation. All rights reserved. All other trademarks and service marks belong to their owners.'
    },
    disclaimers: {},
    sections: [
      'dashboard-gpr',
      'dashboard-lite',
      'dashboard-handoff',
      'layout',
      'manage-gpr',
      'move-money',
      'overdraft',
      'locations',
      'direct-deposit',
      'direct-deposit-mobile-panel',
      'statements',
      'contact-us',
      'restrictions-readonly',
      'feeplan',
      'western-union',
      'disclosure-dictionary',
      'check-deposit',
      'checks',
      'quickbooks-oauth',
      'points',
      'post-handoff-to-activate-register',
      'upgrade',
      'offers',
      'offers-intro',
      'tours',
      'post-install-multi-screen-acquisition-flow',
      'echecks',
      'external-loyalty',
      'secure-inbox',
      'benefit-center',
      'atm-finder',
      'savings',
      'pay-bills',
      'premier-plan',
      'activation',
      'savings-transfer',
      'anytime-alerts',
      'anytime-alerts-upgrade',
      'change-username',
      'change-password',
      'mobile-check-load',
      'feedback',
      'rent-track',
      'direct-deposit-form',
      'dynamic-faq',
      'billpay',
      'card',
      'refer-a-friend',
      'close-account',
      'points-learn-more',
      'family-cards',
      'bank-transfers',
      'handoff',
      'debit-card-transfers',
      'card-use-settings',
      'secure-upload',
      'digital-wallet',
      'me-to-me-transfer',
      'money-gram',
      'survey',
      'dda-activation',
      'debit-card-transfers-disbursement',
      'spend-money',
      'fraud-detection',
      'spending-tracker',
      'connected-banks',
      'interstitials',
      'dd-tracker'
    ]
  }
};

export default variant;
